@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";
@import "../../../styles/themeVariables";

.signInOfferFooter {
  margin-top: $small-desktop-default-gap;

  &>p{
    text-align: center;
    font-size: map.get($smallDesktopFontSizes, "info");
    line-height: map.get($smallDesktopLineHeights,"smallText");
  }
}


@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .signInOfferFooter {
    margin-top: $small-desktop-default-gap;

    &>p{
      text-align: center;
      font-size: map.get($smallDesktopFontSizes, "info");
      line-height: map.get($smallDesktopLineHeights,"smallText");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .signInOfferFooter {
    margin-top: $small-desktop-default-gap;

    &>p{
      text-align: center;
      font-size: map.get($smallDesktopFontSizes, "info");
      line-height: map.get($smallDesktopLineHeights,"smallText");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .signInOfferFooter {
    margin-top: $desktop-default-gap;

    &>p{
      text-align: center;
      font-size: map.get($desktopFontSizes, "info");
      line-height: map.get($smallDesktopLineHeights,"smallText");
    }
  }
}



@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import "../../../styles/line-heights";
@import '../../../media';

.createAccountTitle {
  align-self: flex-start;
}

.createAccountText {
  align-self: flex-start;

  margin: $desktop-default-gap/2 0;
}

.registrationForm {
  width:100%;
  margin: $desktop-default-gap 0;
}

.createAccountButtons {
  &>button:last-child {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .createAccountTitle {
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights,"title");
  }

  .createAccountText {
    margin: $small-desktop-default-gap/2 0 0;
  }

  .registrationForm {
    margin: 0;
  }

  .createAccountButtons {
    &>button:last-child {
      margin-top: $small-desktop-default-gap;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .createAccountTitle {
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights,"title");
  }

  .createAccountText {
    margin: $small-desktop-default-gap/2 0 0;
  }

  .registrationForm {
    margin: 0;
  }

  .createAccountButtons {
    &>button:last-child {
      margin-top: $small-desktop-default-gap;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .createAccountTitle {
    font-size: map.get($desktopFontSizes,"title");
    line-height: map.get($desktopLineHeights,"title");
  }

  .createAccountText {
    margin: $desktop-default-gap/2 0 0;
  }

  .registrationForm {
    margin: 0;
  }

  .createAccountButtons {
    &>button:last-child {
      margin-top: $desktop-default-gap;
    }
  }
}

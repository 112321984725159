@import "../../../styles/sizes";
@import '../../../media';

.onboardingImage {
  border-radius: $picture-border-radius;
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .onboardingImage {
    margin-top: 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .onboardingImage {
    margin-top: 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .onboardingImage {
    margin-top: 0;
  }
}

@import 'src/styles/colors';
.dark{
  .tabs-header-container{
    background-color: $dark-theme;
  }
  .tabs-header-container__back{
    path{
      stroke: $dark-gray;
    }
  }
  .tabs-header-container__star{
    stroke: $dark-gray;
  }


}
.light{
  .tabs-header-container{
    background-color: $light-theme;
  }

  .tabs-header-container__title{
    color:$main-black-color;
  }
}


.tabs-header-container{
  border-bottom: 1px solid rgba(23, 23, 23, 0.10);
  padding: 12px 24px;
  position: sticky;
  top: 0;
  z-index: 2;
  &__back{

  }

  &__star{
    &.active{
      stroke: $main-red-color!important;
      path{
        fill: $main-red-color!important;
      }
    }
  }
}

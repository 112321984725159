@import "../../../styles/sizes";
@import "../../../styles/colors";
@import '../../../media';

.sexualPotentialQuestion {
  text-align: center;
  margin-top: $small-desktop-default-gap;
}

.sexualPotentialAgreeButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .sexualPotentialQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .sexualPotentialAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .sexualPotentialQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .sexualPotentialAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .sexualPotentialQuestion {
    margin-top: $desktop-default-gap;
  }

  .sexualPotentialAgreeButton {
    margin-top: $desktop-default-gap;
  }
}

@use "sass:map";
@import "/src/styles/sizes";
@import "../../styles/fonts-weights";
@import '/src/styles/themeVariables';
@import '../../media';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-bottom: $small-desktop-app-container-side-padding/2;

  &>h4 {
    font-size: map.get($smallDesktopFontSizes,"header");
  }
}

.headerIconsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flagIcon {
  cursor: pointer;

  height: $small-desktop-header-icons-size;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .header {
    margin-bottom: $small-desktop-app-container-side-padding/2;

      &>h4 {
        font-size: map.get($smallDesktopFontSizes,"header");
      }
  }

  .flagIcon {
    height: $small-desktop-header-icons-size;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .header {
    margin-bottom: $small-desktop-app-container-side-padding/2;

    &>h4 {
      font-size: map.get($smallDesktopFontSizes,"header");
    }
  }

  .flagIcon {
    height: $small-desktop-header-icons-size;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height)  {
  .header {
    margin-bottom: $desktop-app-container-side-padding/2;

    &>h4 {
      font-size: map.get($desktopFontSizes,"header");
    }
  }

  .flagIcon {
    height: $desktop-header-icons-size;
  }
}


@import "../../../styles/sizes";
@import '../../../media';

.enticingOneQuestion {
  text-align: center;
  margin-top: $small-desktop-default-gap;
}

.enticingOneAgreeButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height){
  .enticingOneQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .enticingOneAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .enticingOneQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .enticingOneAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .enticingOneQuestion {
    margin-top: $desktop-default-gap*2;
  }

  .enticingOneAgreeButton {
    margin-top: $desktop-default-gap;
  }
}

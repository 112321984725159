@use "sass:map";
@import 'src/styles/colors';
@import 'src/styles/themeVariables';
@import '../../media';

.dark{
  .tabs-footer__arrows{
    svg{
      path{
        stroke: $light-theme!important;
      }
    }
    &__left, &__right{
    &.inactive {
      svg {
        path {
          stroke: $dark-theme-bg-bar !important;
        }
      }
    }
  }
  }
}
.tabs-footer{
  position: fixed;
  bottom: 90px;
  padding: 8px 24px 16px;
  width: inherit;
  max-width: inherit;
  @media screen and (max-width: $smartphone-width) {
    width: 100%;
  }
  &__arrows{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    &__left, &__right{

      &.inactive{
        svg{
          path{
            stroke: #B6B2B2;
          }
        }
      }

    }
    &__right{
      transform: rotateY(180deg);
    }
  }
  &__bars{
    display: flex;
  }
}
@import "styles/colors";
.light{
  .svg-loader{
    svg{
      path{

      }
    }
  }
}
.no-scroll {
  overflow: hidden !important;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* dark background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* higher than any other content */
}

.loader {
  color: $main-white-color;
  text-align: center;
  font-weight: bold;
}

.svg-loader {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
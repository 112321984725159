@use "sass:map";
@import "/src/styles/sizes.scss";
@import "styles/fonts-weights";
@import "styles/line-heights";

$mobile-app-container-width: 360px;
$small-desktop-app-container-width: 408px;
$desktop-app-container-width: 535px;

$smartphone-height: 1000px;
$smartphone-width: 450px;

$small-desktop-min-height: 570px;
$small-desktop-min-width: 451px;

$desktop-min-height: 860px;

@media only screen and (max-height: $smartphone-height) {
  h2 {
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes, "largeTitle");
    margin: 0;
    line-height: map.get($smallDesktopLineHeights, "largeTitle");
  }

  h4 {
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes, "title");
    margin: 0;
    line-height: map.get($smallDesktopLineHeights, "title");
  }

  h6, p {
    font-weight: map.get($font-weights, "text");
    font-size: map.get($smallDesktopFontSizes, "text");
    margin: 0;
    line-height: map.get($smallDesktopLineHeights, "text");
  }

  button {
    font-size: map.get($smallDesktopFontSizes, "text");
    line-height: map.get($smallDesktopLineHeights, "text");
  }

  label {
    font-size:  map.get($smallDesktopFontSizes,"smallText");
  }

  input::placeholder {
    font-size:  map.get($smallDesktopFontSizes,"text");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  h2 {
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes, "largeTitle");
    margin: 0;
    line-height: map.get($smallDesktopLineHeights, "largeTitle");
  }

  h4 {
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes, "title");
    margin: 0;
    line-height: map.get($smallDesktopLineHeights, "title");
  }

  h6, p {
    font-weight: map.get($font-weights, "text");
    font-size: map.get($smallDesktopFontSizes, "text");
    margin: 0;
    line-height: map.get($smallDesktopLineHeights, "text");
  }

  button {
    font-size: map.get($smallDesktopFontSizes, "text");
    line-height: map.get($smallDesktopLineHeights, "text");
  }

  label {
    font-size:  map.get($smallDesktopFontSizes,"smallText");
  }

  input::placeholder {
    font-size:  map.get($smallDesktopFontSizes,"text");
  }
}

@media only screen and (min-height: $desktop-min-height) {
  h2 {
    font-weight: map.get($font-weights, "title");
    font-size: map.get($desktopFontSizes, "largeTitle");
    margin: 0;
    line-height: map.get($desktopLineHeights, "largeTitle");
  }

  h4 {
    font-weight: map.get($font-weights, "title");
    font-size: map.get($desktopFontSizes, "title");
    margin: 0;
    line-height: map.get($desktopLineHeights, "title");
  }

  h6, p {
    font-weight: map.get($font-weights, "text");
    font-size: map.get($desktopFontSizes, "text");
    margin: 0;
    line-height: map.get($desktopLineHeights, "text");
  }

  button {
    font-size: map.get($desktopFontSizes, "text");
    line-height: map.get($desktopLineHeights, "text");
  }

  label {
    font-size:  map.get($desktopFontSizes,"smallText");
  }

  input::placeholder {
    font-size:  map.get($desktopFontSizes,"text");
  }
}

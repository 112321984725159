@import "../../../styles/sizes";
@import '../../../media';
@import "../../../styles/colors";

.appPreviewContainer {
  margin: 0 0 $small-desktop-default-gap*4 $small-desktop-carousel-container-side-margin;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
  text-align: center;
}

.appPreviewTitle {
  text-align: center;
  margin-bottom: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .appPreviewContainer {
    margin: 0 0 $small-desktop-default-gap*4 $small-desktop-carousel-container-side-margin;
    }

  .appPreviewTitle {
    margin-bottom: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .appPreviewContainer {
    margin: 0 0 $small-desktop-default-gap*4 $small-desktop-carousel-container-side-margin;
   }

  .appPreviewTitle {
    margin-bottom: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .appPreviewContainer {
    margin: 0 0 $desktop-default-gap*4 $desktop-carousel-container-side-margin;
  }

  .appPreviewTitle {
    margin-bottom: $desktop-default-gap;
  }
}



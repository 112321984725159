@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";

.moneyBackContainer {
  margin: $small-desktop-default-gap*6 0;
}

.moneyBackInfoContainer {
  padding: $small-desktop-default-gap*2 $small-desktop-default-gap;
  position: relative;

  &> img {
    width: 100px;
    position:absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    top:-50px;
  }

  &>h4{
    margin-top: $small-desktop-default-gap*2;
    text-align: center;
    font-size: 20px;
  }

  &>p {
    text-align: center;
    font-weight: map.get($font-weights, "boldText");
    font-size: map.get($smallDesktopFontSizes,"text");
    line-height: map.get($smallDesktopLineHeights, "text");
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .moneyBackContainer {
    margin: $small-desktop-default-gap*6 0;
  }

  .moneyBackInfoContainer {
    padding: $small-desktop-default-gap*2 $small-desktop-default-gap;

    &> img {
      width: 100px;
      top:-50px;
    }

    &>h4{
      margin-top: $small-desktop-default-gap*2;
      font-size: 20px;
    }

    &>p {
      font-size: map.get($smallDesktopFontSizes,"text");
      line-height: map.get($smallDesktopLineHeights, "text");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .moneyBackContainer {
    margin: $small-desktop-default-gap*6 0;
  }

  .moneyBackInfoContainer {
    padding: $small-desktop-default-gap*2 $small-desktop-default-gap;

    &> img {
      width: 100px;
      top:-50px;
    }

    &>h4{
      margin-top: $small-desktop-default-gap*2;
      font-size: 20px;
    }

    &>p {
      font-size: map.get($smallDesktopFontSizes,"text");
      line-height: map.get($smallDesktopLineHeights, "text");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .moneyBackContainer {
    margin: $desktop-default-gap*6 0;
  }

  .moneyBackInfoContainer {
    padding: $desktop-default-gap*2 $desktop-default-gap;

    &> img {
      width: 130px;
      top:-65px;
    }

    &>h4{
      margin-top: $desktop-default-gap*2;
      font-size: map.get($desktopFontSizes,"header");
    }

    &>p {
      font-size: map.get($desktopFontSizes,"text");
      line-height: map.get($desktopLineHeights, "text");
    }
  }
}


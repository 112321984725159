@import 'src/styles/colors';
@import 'src/styles/sizes';
@import "src/media";
.light{
  .footer-container{
    background-color: $main-white-color;
  }
  color:$light-theme-text;
}
.footer-container{
  position: fixed;
  bottom: 0;
  z-index: 33;
  background-color: $dark-theme-background;
  width: inherit;
  @media screen and (max-width: $desktop-app-container-width) {
    width: 100vw;
  }
  &__wrapper{
    justify-content: space-between;
    padding: 16px 24px 16px;
  }
  &__icon{
    justify-content: space-between;
    align-items: center;
    svg{
      margin-bottom: 12px;
    }
    &.active{
      &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){
        path{
          fill: #CF0A0A;
        }
      }
      &:nth-child(1){
        svg{
          path {
            &:nth-of-type(1) {
                fill: #CF0A0A;
            }

            &:nth-of-type(2) {
                fill: #CF0A0A;
            }

            &:nth-of-type(3) {
                fill: #CF0A0A;
            }

            &:nth-of-type(4) {
                fill: #CF0A0A;
            }

            &:nth-of-type(5) {
                stroke: #CF0A0A;

            }
          }
        }
      }
    }
  }
}


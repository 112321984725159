@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.distantRelationshipTitle {
  text-align: center;
  margin-top: $small-desktop-default-gap;
}

.distantRelationshipText {
  text-align: center;
  margin-top: $small-desktop-default-gap;
}

.distantRelationshipQuestion {
  text-align: center;
  font-weight: map.get($font-weights,"title");
  margin-top: $small-desktop-default-gap;
}

.distantRelationshipButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .distantRelationshipTitle {
    margin-top: $small-desktop-default-gap;
  }

  .distantRelationshipText {
    margin-top: $small-desktop-default-gap;
  }

  .distantRelationshipQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .distantRelationshipButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .distantRelationshipTitle {
    margin-top: $small-desktop-default-gap;
  }

  .distantRelationshipText {
    margin-top: $small-desktop-default-gap;
  }

  .distantRelationshipQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .distantRelationshipButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .distantRelationshipTitle {
    margin-top: $desktop-default-gap;
  }

  .distantRelationshipText {
    margin-top: $desktop-default-gap;
  }

  .distantRelationshipQuestion {
    margin-top: $desktop-default-gap;
  }

  .distantRelationshipButton {
    margin-top: $desktop-default-gap;
  }
}

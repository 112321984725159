@import "/src/styles/sizes";
@import '../../media';

.themeIcon {
  cursor: pointer;
  margin-left: 8px;

  width: $small-desktop-header-icons-size;
  height: $small-desktop-header-icons-size;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .themeIcon {
    width: $small-desktop-header-icons-size;
    height: $small-desktop-header-icons-size;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .themeIcon {
    width: $small-desktop-header-icons-size;
    height: $small-desktop-header-icons-size;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height)  {
  .themeIcon {
    width: $desktop-header-icons-size;
    height: $desktop-header-icons-size;
  }
}


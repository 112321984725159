@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.relationshipIssueQuestion {
  text-align: center;
  font-weight: map.get($font-weights,"title");
  margin-top: $small-desktop-default-gap;
}

.relationshipIssueOptionButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .relationshipIssueOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .relationshipIssueOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .relationshipIssueQuestion {
    margin: $desktop-default-gap 0;
  }

  .relationshipIssueOptionButton {
    margin-top: $desktop-default-gap;
  }
}

@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import "../../../styles/colors";
@import '../../../media';
@import "../../../styles/line-heights";

.statesContainer {
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: $small-desktop-default-gap*2;
  margin-top: $small-desktop-default-gap;
}

.currentIntimateState{
  &>h6{
    font-weight: map.get($font-weights, "boldText");
    text-align: center;
    margin-bottom: $small-desktop-default-gap/2;
  }

  &>img{
    width: 100%;
  }

  &>p{
    margin-top: $small-desktop-default-gap;
    text-align: center;
    font-weight: map.get($font-weights, "boldText");
  }
}

.goalIntimateState{
  &>h6{
    font-weight: map.get($font-weights, "title");
    text-align: center;
    margin-bottom: $small-desktop-default-gap/2;
    color:$main-red-color;
  }

  &>img{
    width: 100%;
    border-radius: $picture-border-radius;
    box-shadow: -6px 6px $main-red-color;
  }

  &>p{
    margin-top: $small-desktop-default-gap;
    text-align: center;
    font-weight: map.get($font-weights, "boldText");
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .statesContainer {
    row-gap: $small-desktop-default-gap*2;
    margin-top: $small-desktop-default-gap;
  }

  .currentIntimateState{
    &>h6{
      margin-bottom: $small-desktop-default-gap/2;
    }

    &>p{
      margin-top: $small-desktop-default-gap;
    }
  }

  .goalIntimateState{
    &>h6{
      margin-bottom: $small-desktop-default-gap/2;
    }

    &>img{
    }

    &>p{
      margin-top: $small-desktop-default-gap;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .statesContainer {
    row-gap: $small-desktop-default-gap*2;
    margin-top: $small-desktop-default-gap;
  }

  .currentIntimateState{
    &>h6{
      margin-bottom: $small-desktop-default-gap/2;
    }

    &>p{
      margin-top: $small-desktop-default-gap;
    }
  }

  .goalIntimateState{
    &>h6{
      margin-bottom: $small-desktop-default-gap/2;
    }

    &>img{
    }

    &>p{
      margin-top: $small-desktop-default-gap;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .statesContainer {
    row-gap: $desktop-default-gap*2;
    margin-top: $desktop-default-gap;
  }

  .currentIntimateState{
    &>h6{
      margin-bottom: $desktop-default-gap/2;
    }

    &>p{
      margin-top: $desktop-default-gap;
    }
  }

  .goalIntimateState{
    &>h6{
      margin-bottom: $desktop-default-gap/2;
    }

    &>img{
    }

    &>p{
      margin-top: $desktop-default-gap;
    }
  }
}



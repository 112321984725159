@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/colors";
@import "../../../styles/themeVariables";

.signUpOfferContainer {
  display:flex;
  flex-direction: column;
  border-radius: $default-border-radius;

  &.light{
    background-color: $main-white-color;
  }

  &.dark {
    background-color: map.get($darkTheme, "colorBackground");
  }
}

@import 'src/styles/colors';
@import 'src/styles/themeVariables';
.days{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: inherit;
  gap: 2px;
  padding: 6px 0;
}

.empty-day, .day{
  width: 40px;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datepicker{
  display: flex;
  flex-direction: column;
  max-width: 327px;
  width: 327px;
  height: 330px;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  border-radius: 13px;
}
.dark{
  .datepicker{
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.04);
    color: $main-white-color;
    .month-header{
      svg{
        path{
          stroke:$main-white-color;
        }
      }
    }
    .selected{
      color: $main-black-color;
    }
  }
  .calendar-content-wrapper{
    &__left-part{
      &:nth-child(1){
        color: $main-black-color;
      }
    }
  }
}
.light{
  .datepicker{
    background:  $main-white-color;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.10);
    color: $main-black-color;
    .month-header{
      svg{
        path{
          stroke:$main-black-color;
        }
      }
    }
    .selected{
      color: $main-white-color;
    }
  }
}
.date-picker-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.day-of-week{
  color: $dark-theme-border-color;
  width: 40px;
  text-align: center;
}
.days-of-week {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  color: inherit;
  width: 100%;
}

.month-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.day {
  &.selected{
    border-radius: 50%;
    background: $main-red-color;
  }
}

.calendar-content-wrapper{
  gap: 16px;
  height: auto;
  &__left-part{
    background-color: $light-gray;
    min-width: 42px;
    align-items: center;
    justify-content: center;
    height: inherit;
    min-height: 100px;
    border-radius: 8px;
  }
  &__right-part{
    row-gap: 4px;
    padding: 12px 0;
  }
}
@use "sass:map";
@import "../../styles/sizes";
@import "../../styles/fonts-weights";
@import '../../media';
@import "../../styles/colors";
@import "../../styles/line-heights";

.timerCountdown {
  font-weight: map.get($font-weights, "title");
  font-size: map.get($smallDesktopFontSizes,"header");
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .timerCountdown {
    font-size: map.get($smallDesktopFontSizes,"header");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .timerCountdown {
    font-size: map.get($smallDesktopFontSizes,"header");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .timerCountdown {
    font-size: map.get($desktopFontSizes,"header");
  }
}

@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/colors";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import '../../../styles/themeVariables';

.form-group{
  position:relative;
  .form-control{
    border-radius: 12px;
  }
}

.form-group>label{
  position: absolute;
  top: -15px;
  left: 15px;
  padding: 5px;
  font-weight: map.get($font-weights,"text");

  &.light{
    background-color: $light-theme;
    color: $input-border-color;
  }

  &.dark{
    color: $dark-theme-border-color;
    background-color: $dark-theme;
  }
}

.form-group>input{
  border: 1px solid $input-border-color;
  width:100%;
  height: 45px;

  &.light{
    background-color: $light-theme;
    border: 1px solid $input-border-color;
    color: $light-theme-text;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      border: 1px solid $input-border-color;
      -webkit-text-fill-color: map.get($lightTheme, "colorText");
      -webkit-box-shadow: 0 0 0 30px $light-theme inset !important;
    }
  }

  &.dark{
    background-color: $dark-theme;
    border: 1px solid $dark-theme-border-color;
    color: $dark-theme-text;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      border: 1px solid $dark-theme-border-color;
      -webkit-text-fill-color: map.get($darkTheme, "colorText");
      -webkit-box-shadow: 0 0 0 30px $dark-theme inset !important;
    }
  }

  &:focus {
    border: 1px solid $input-border-color;
    box-shadow: $input-focus-box-shadow;
  }
}

.inputErrorMessage {
  font-size: map.get($smallDesktopFontSizes,"text");
  color: $main-red-color;
  margin: $small-desktop-default-gap/2 0 $small-desktop-default-gap;
}

.inputErrorBoundary {
  box-shadow: $input-focus-box-shadow;
  border-color:$main-red-color !important;
}

.labelErrorBoundary {
  color:$main-red-color !important;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .form-group{
    margin:$small-desktop-default-gap 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .form-group{
    margin:$small-desktop-default-gap 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .form-group{
    margin:$desktop-default-gap 0;
  }
}

.premium-empty-content{
  height: 100%;
  padding: 24px;
  min-height: 78dvh;
  text-align: center;
  &__text{
    opacity: 70%;
  }
  &__subtitle{
    font-weight: 500;
  }
}
@use "sass:map";
@import "/src/styles/sizes";
@import "/src/styles/colors";
@import "../../../styles/fonts-weights";
@import '../../../media';

.button {
  border-radius: $default-border-radius;
  border: 3px solid $main-red-color;
  font-weight: map.get($font-weights, "title");
  text-align: center;
  cursor: pointer;
  padding: 11px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled,
  &[disabled]{
    cursor: default;
    opacity: 0.5;
  }

  &>img {
    width: 15px;
    margin-right: 15px;
  }
}

.whiteButtonTheme {
  &.light{
    background-color: $light-theme;
    color: $dark-theme;
  }

  &.dark {
    background-color: transparent;
    color: $dark-theme-text;
  }
}

.whiteButtonTheme:hover {
  &.light{
    background-color: $main-red-color;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.9);
  }

  &.dark {
    background-color: transparent;
    box-shadow: inset 0 0 100px 100px $transparent-red-color;
  }
}

.redButtonTheme {
  color: $light-theme;
  background-color: $main-red-color;
}

.redButtonTheme:hover {
  background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
.button {
  padding: 11px 10px;

  &>img {
    width: 15px;
    margin-right: 15px;
  }
}
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .button {
    padding: 11px 10px;

    &>img {
      width: 15px;
      margin-right: 15px;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .button {
    padding: 15px 0;

    &>img {
      width: 20px;
      margin-right: 15px;
    }
  }
}

@use "sass:map";
@import "../../../../styles/sizes";
@import "../../../../styles/fonts-weights";
@import '../../../../media';
@import "../../../../styles/colors";
@import "../../../../styles/line-heights";

.payCardsContainer {
  margin-top: $small-desktop-default-gap;
  display: inline-block;
  width: 100%;

  &>img {
    width: 51px;
    margin:0 7px 7px 0;
  }
  &>svg {
    width: 51px;
    margin:0 7px 7px 0;
  }
}


@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .payCardsContainer {
    width: 100%;

    &>img {
      width: 51px;
      margin:0 7px 7px 0;
    }
    &>svg {
      width: 51px;
      margin:0 7px 7px 0;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .payCardsContainer {
    width: 100%;

    &>img {
      width: 35px;
      margin:0 7px 7px 0;
    }
    &>svg {
      width: 51px;
      margin:0 7px 7px 0;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .payCardsContainer {
    width: 100%;

    &>img {
      width: 51px;
      margin:0 7px 7px 0;
    }
    &>svg {
      width: 51px;
      margin:0 7px 7px 0;
    }
  }
}

@import "/src/styles/colors";
@import "/src/styles/sizes";
@import '../../media';

.divisionsContainer {
  display:flex;
  width:100%;
  margin-bottom: $small-desktop-app-container-side-padding/2
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .divisionsContainer {
    margin-bottom: $small-desktop-app-container-side-padding/2
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .divisionsContainer {
    margin-bottom: $small-desktop-app-container-side-padding/2
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .divisionsContainer {
    margin-bottom: $desktop-app-container-side-padding/2
  }
}



@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";

.sighUpOfferImageContainer{
  display:flex;
  flex-direction: column;
  position:relative;

  &>.signUpOfferImageTitle {
    position:absolute;
    bottom:0;
    margin: 0 0 10px $sign-up-offers-container-padding;

    color: $main-white-color;
  }
}

.signUpOfferImageTitle {
  font-size: map.get($smallDesktopFontSizes,"imageTitle");
  line-height: map.get($smallDesktopLineHeights,"largeTitle");
}

.signUpOfferTitle {
  margin: $small-desktop-default-gap $sign-up-offers-container-padding 0;
  color: $main-red-color;
}

.signUpOfferText {
  margin: $small-desktop-default-gap $sign-up-offers-container-padding 0;
  line-height: map.get($smallDesktopLineHeights,"header");
  font-weight: map.get($font-weights, "title");
  font-size: map.get($smallDesktopFontSizes,"header");
}

.offersContainer {
  display: flex;
  flex-direction: column;
  margin: $small-desktop-default-gap $sign-up-offers-container-padding;
}

.offerContainer {
  display:flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $small-desktop-default-gap;

  &:last-child {
    margin-bottom: $desktop-default-gap;
  }

  &>img{
    width: $small-desktop-offer-icons-size;
    height: $small-desktop-offer-icons-size;
    margin-right: $sign-up-offers-container-padding;
  }
}

.offerDescription{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &>h6 {
    font-weight: map.get($font-weights, "title");
    margin-bottom: $small-desktop-default-gap/4;
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .signUpOfferImageTitle {
    font-size: map.get($smallDesktopFontSizes,"imageTitle");
    line-height: map.get($smallDesktopLineHeights,"largeTitle");
  }

  .signUpOfferText {
    line-height: map.get($smallDesktopLineHeights,"header");
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes,"header");
  }

  .offersContainer {
    margin: $small-desktop-default-gap $sign-up-offers-container-padding;
  }

  .offerContainer {
    margin-bottom: $small-desktop-default-gap;

    &:last-child {
      margin-bottom: $desktop-default-gap;
    }

    &>img{
      width: $small-desktop-offer-icons-size;
      height: $small-desktop-offer-icons-size;
      margin-right: $sign-up-offers-container-padding;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .signUpOfferImageTitle {
    font-size: map.get($smallDesktopFontSizes,"imageTitle");
    line-height: map.get($smallDesktopLineHeights,"largeTitle");
  }

  .signUpOfferText {
    line-height: map.get($smallDesktopLineHeights,"header");
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes,"header");
  }

  .offersContainer {
    margin: $small-desktop-default-gap $sign-up-offers-container-padding;
  }

  .offerContainer {
    margin-bottom: $small-desktop-default-gap;

    &:last-child {
      margin-bottom: $desktop-default-gap;
    }

    &>img{
      width: $small-desktop-offer-icons-size;
      height: $small-desktop-offer-icons-size;
      margin-right: $sign-up-offers-container-padding;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .signUpOfferImageTitle {
    font-size: map.get($desktopFontSizes,"imageTitle");
    line-height: map.get($desktopLineHeights,"largeTitle");
  }

  .signUpOfferText {
    line-height: map.get($desktopLineHeights,"header");
    font-weight: map.get($font-weights, "title");
    font-size: map.get($desktopFontSizes,"header");
  }

  .offersContainer {
    margin: $desktop-default-gap $sign-up-offers-container-padding;
  }

  .offerContainer {
    margin-bottom: $desktop-default-gap;

    &:last-child {
      margin-bottom: $desktop-default-gap;
    }

    &>img{
      width: $desktop-offer-icons-size;
      height: $desktop-offer-icons-size;
      margin-right: $sign-up-offers-container-padding;
    }
  }
}


@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";

.readinessContainer {
  margin: $small-desktop-default-gap*4 0 $small-desktop-default-gap*3;
}

.readinessTitle {
  margin-top: $small-desktop-default-gap;
  text-align: center;
}

.newJourneyText {
  margin: $small-desktop-default-gap 0 $small-desktop-default-gap*3;
  text-align: center;
  font-weight: map.get($font-weights, "boldText");
}

.readinessValue {
  text-align: center;
  color: $main-red-color;
}

.howEasyContainer{
  border-radius: $default-border-radius;
  background-color: $main-red-color;

  &>ul {
    color:$light-theme;
    padding: 10px 10px 10px $sign-up-offers-container-padding*2;
    line-height: map.get($smallDesktopLineHeights, "title");
  }
}

.howEasyLogo{
  position: relative;

  &>.howEasyLogoTitle {
    position:absolute;
    bottom:0;
    margin: 0 0 10px $sign-up-offers-container-padding;
    width:60%;

    color: $main-white-color;
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .readinessContainer {
    margin: $small-desktop-default-gap*4 0 $small-desktop-default-gap*3;
  }

  .readinessTitle {
    margin-top: $small-desktop-default-gap;
  }

  .newJourneyText {
    margin: $small-desktop-default-gap 0 $small-desktop-default-gap*3;
  }

  .howEasyContainer{
    &>ul {
      line-height: map.get($smallDesktopLineHeights, "title");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .readinessContainer {
    margin: $small-desktop-default-gap*4 0 $small-desktop-default-gap*3;
  }

  .readinessTitle {
    margin-top: $small-desktop-default-gap;
  }

  .newJourneyText {
    margin: $small-desktop-default-gap 0 $small-desktop-default-gap*3;
  }

  .howEasyContainer{
    &>ul {
      line-height: map.get($smallDesktopLineHeights, "title");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .readinessContainer {
    margin: $desktop-default-gap*4 0 $desktop-default-gap*2;
  }

  .readinessTitle {
    margin-top: $desktop-default-gap;
  }

  .newJourneyText {
    margin: $desktop-default-gap 0 $desktop-default-gap*3;
  }

  .howEasyContainer{
    &>ul {
      line-height: map.get($smallDesktopLineHeights, "title");
    }
  }
}



@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/colors";
@import "../../../styles/fonts-weights";
@import "../../../styles/line-heights";
@import '../../../media';

.newAccountMessage {
  color: $main-red-color;
  text-align: center;
  margin-bottom: $small-desktop-default-gap*2;
  font-size:  map.get($smallDesktopFontSizes,"header");
  line-height: map.get($smallDesktopLineHeights,"header");
}

.logInTitle {
  text-align: center;
  margin-bottom: $small-desktop-default-gap;

  &>img {
    width:30px;
    margin-left: 10px;
  }
}

.logInForm {
  width:100%;
  margin: $desktop-default-gap 0;
}

.logInButtons {
  width: 100%;

  &>button:last-child {
    margin-top: $small-desktop-default-gap;
  }
}

.doNotHaveAccount {
  margin-top: $small-desktop-default-gap;
  font-size: map.get($smallDesktopFontSizes,"smallText");
  line-height: map.get($smallDesktopLineHeights,"text");
  font-weight: map.get($font-weights, "boldText");

  &>a {
    color: $main-red-color;
    text-decoration: none;
  }

  &>a:hover {
    opacity: 0.7;
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .logInTitle {
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights,"title");
    margin-bottom: $small-desktop-default-gap;
  }

  .logInForm {
    margin: 0;
  }

  .logInButton {

    &>button:last-child {
      margin-top: $small-desktop-default-gap;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .logInTitle {
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights,"title");
    margin-bottom: $small-desktop-default-gap;
  }

  .logInForm {
    margin: 0;
  }

  .logInButton {

    &>button:last-child {
      margin-top: $small-desktop-default-gap;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .logInTitle {
    font-size: map.get($desktopFontSizes,"title");
    line-height: map.get($desktopLineHeights,"title");
    margin-bottom: $desktop-default-gap;
  }

  .logInForm {
    margin: 0;
  }

  .logInButton {

    &>button:last-child {
      margin-top: $desktop-default-gap;
    }
  }
}

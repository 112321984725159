@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/colors";
@import "../../../styles/fonts-weights";
@import "../../../media";

.checkboxContainer {
  display: flex;
  align-items: flex-start;

  &>input {
    width: 25px;
    height: 25px;
    margin: 0 10px 0 0;
    border: 1px solid $input-border-color;
  }

  &>label {
    font-size:  map.get($smallDesktopFontSizes,"smallText");
    font-weight: map.get($font-weights,"text");
    align-self: center;
    margin: 0;
  }
}

.tooltip{
  font-size:  map.get($smallDesktopFontSizes,"text") !important;
  background-color: $main-red-color !important;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .checkboxContainer {
    & > label {
      font-size:  map.get($smallDesktopFontSizes,"smallText");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .checkboxContainer {
    & > label {
      font-size:  map.get($smallDesktopFontSizes,"smallText");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .checkboxContainer {
    & > label {
      font-size:  map.get($desktopFontSizes,"smallText");
    }
  }
}

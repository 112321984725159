@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";

.pointsWillLearnContainer {
  text-align: center;
  margin-top: $small-desktop-default-gap;

  &>.tryAppTextTitle{
    margin-top: $small-desktop-default-gap;
  }

  &>.tryTerm{
    margin-top: $small-desktop-default-gap/2;
    color: $main-red-color;
  }

  &>.willLearnTitle{
    margin-top: $small-desktop-default-gap/2;
  }
}

.pointsWillLearn {
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  margin-top: $small-desktop-default-gap*2;
}

.pointWillLearn {
  position:relative;

  &>img{
    width:100%;
  }

  &>h6{
    position:absolute;
    bottom:0;
    margin: 0 5px 5px;
    color: $dark-theme-text;
    text-align: left;
    font-weight: map.get($font-weights, "boldText");
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .pointsWillLearnContainer {
    margin-top: $small-desktop-default-gap;

    &>.tryAppTextTitle{
      margin-top: $small-desktop-default-gap;
    }

    &>.tryTerm{
      margin-top: $small-desktop-default-gap/2;
    }

    &>.willLearnTitle{
      margin-top: $small-desktop-default-gap/2;
    }
  }

  .pointsWillLearn {
    margin-top: $small-desktop-default-gap*2;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .pointsWillLearnContainer {
    margin-top: $small-desktop-default-gap;

    &>.tryAppTextTitle{
      margin-top: $small-desktop-default-gap;
    }

    &>.tryTerm{
      margin-top: $small-desktop-default-gap/2;
    }

    &>.willLearnTitle{
      margin-top: $small-desktop-default-gap/2;
    }
  }

  .pointsWillLearn {
    margin-top: $small-desktop-default-gap*2;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .pointsWillLearnContainer {
    margin-top: $desktop-default-gap;

    &>.tryAppTextTitle{
      margin-top: $desktop-default-gap;
    }

    &>.tryTerm{
      margin-top: $desktop-default-gap/2;
    }

    &>.willLearnTitle{
      margin-top: $desktop-default-gap/2;
    }
  }

  .pointsWillLearn {
    margin-top: $desktop-default-gap*2;
  }
}



$main-red-color: #cf0a0a;
$main-black-color: #171717;
$main-white-color: #f4f4f4;

$transparent-red-color: rgba(211,34,34,0.1);

$light-theme: #ffffff;
$light-theme-text: #171717;

$dark-theme: #1f1e1e;
$dark-theme-background: #171717;
$dark-theme-text: #fff;
$dark-theme-border-color: rgba(249, 249, 249, 0.5);
$dark-theme-bg-bar: rgba(255, 255, 255, 0.1);
$dark-theme-bg-bar-1: rgba(255, 255, 255, 0.5);
$dark-theme-bg-bar-2: rgb(23, 23, 23, 0.5);
$dark-theme-bg-bar-4: rgb(23, 23, 23, 0.3);
$dark-theme-bg-bar-3: rgb(23, 23, 23, 0.05);

$input-border-color: rgba(23,23,23,0.5);
$input-focus-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(211,34,34,0.5);

$light-gray: #F4F4F4;
$subtitle-color: rgba(23, 23, 23, 0.60);

$offer-games-container-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 16px rgba(211,34,34,0.7);

$dark-gray: #B6B2B2;

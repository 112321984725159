@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.emailVerificationTitle {
  text-align: center;
}

.emailVerificationText {
  text-align: center;
  margin: $small-desktop-default-gap 0;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .emailVerificationText {
    margin: $small-desktop-default-gap 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .emailVerificationText {
    margin: $small-desktop-default-gap 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .emailVerificationText {
    margin: $desktop-default-gap 0;
  }
}



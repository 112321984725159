@use "sass:map";
@import "../../styles/sizes";
@import "../../styles/fonts-weights";
@import '../../media';
@import "../../styles/colors";
@import "../../styles/line-heights";
@import "../../styles/themeVariables";

.darkBox {
  padding: $sign-up-offers-container-padding $sign-up-offers-container-padding;
  background: map.get($darkTheme, "colorBackground");
  box-shadow: $offer-games-container-box-shadow;
  border-radius: $default-border-radius;
  display: flex;
  flex-direction: column;
  margin-top: $small-desktop-default-gap;
}

.darkBoxTitle {
  text-align: center;
  font-weight: map.get($font-weights, "title");
  color: map.get($darkTheme, "colorText");
  font-size:15px;
  margin-bottom: $small-desktop-default-gap/2;
}

.infoBoxContainer {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: $small-desktop-default-gap;
  margin-top: 10px;
}

.infoPointContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &>p{
    color: map.get($darkTheme, "colorText");
  }

  &>img {
    width: $small-desktop-offer-game-icons-size;
    height: auto;
    margin-right: 15px;
  }
}


.infoPointContainerLastChild{
  grid-column: span 2;
}

.darkBoxFooter {
  text-align: center;
  font-weight: map.get($font-weights, "title");
  color: map.get($darkTheme, "colorText");
  font-size:15px;
  margin-top:$small-desktop-default-gap;
  line-height: map.get($smallDesktopLineHeights, "header");
}

.light{
  .darkBoxTitle {
    color: map.get($lightTheme, "colorText");
  }
  .darkBox{
    background: map.get($lightTheme, "colorBackground");
  }
  .infoPointContainer {
    &>p{
      color: map.get($lightTheme, "colorText");
    }
  }
  .darkBoxFooter{
    color: map.get($lightTheme, "colorText");
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .darkBox {
    padding: $sign-up-offers-container-padding $sign-up-offers-container-padding;
    margin-top: $small-desktop-default-gap;
  }

  .infoBoxContainer {
    display:flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 10px;
  }

  .darkBoxTitle {
    font-size:15px;
    margin-bottom: $small-desktop-default-gap/2;
  }

  .infoPointContainer {
    &>img {
      width: $small-desktop-offer-game-icons-size;
      margin-right: 15px;
    }
  }

  .darkBoxFooter {
    font-size:15px;
    margin-top:$small-desktop-default-gap;
    line-height: map.get($smallDesktopLineHeights, "header");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .darkBox {
    padding: $sign-up-offers-container-padding $sign-up-offers-container-padding;
    margin-top: $small-desktop-default-gap;
  }

  .infoBoxContainer {
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: $small-desktop-default-gap;
    margin-top: 10px;
  }

  .darkBoxTitle {
    font-size:15px;
    margin-bottom: $small-desktop-default-gap/2;
  }

  .infoPointContainer {
    &>img {
      width: $small-desktop-offer-game-icons-size;
      margin-right: 15px;
    }
  }

  .darkBoxFooter {
    font-size:15px;
    margin-top:$small-desktop-default-gap;
    line-height: map.get($smallDesktopLineHeights, "header");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .darkBox {
    padding: $sign-up-offers-container-padding $sign-up-offers-container-padding*2;
    margin-top: $desktop-default-gap;
  }

  .infoBoxContainer {
    grid-template-columns: 1fr 1fr;
    column-gap: $desktop-default-gap;
    row-gap: $desktop-default-gap;
    margin-top: $desktop-default-gap;
  }

  .darkBoxTitle {
    font-size:18px;
    margin-bottom: $desktop-default-gap/2;
  }

  .infoPointContainer {
    &>img {
      width: $desktop-offer-game-icons-size;
    }
  }

  .darkBoxFooter {
    font-size:18px;
    margin-top:$desktop-default-gap;
    line-height: map.get($desktopLineHeights, "header");
  }
}



@use "sass:map";
@import "../../../styles/sizes";
@import '../../../media';

.ageCheckFooter{
  text-align: center;
  display: flex;
  justify-content: flex-end;
  font-size: map.get($smallDesktopFontSizes, "smallText");
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .ageCheckFooter{
    font-size: map.get($smallDesktopFontSizes, "smallText");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .ageCheckFooter{
    font-size: map.get($smallDesktopFontSizes, "smallText");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height)  {
  .ageCheckFooter{
    font-size: map.get($desktopFontSizes, "smallText");
  }
}


@import "src/styles/themeVariables";
@import "src/styles/colors";

.dark{
  .progress-container__block-wrapper, .progress-container__block-title{
  background-color:$main-black-color !important;
  }
  .chart-icon{
    fill:$dark-theme-bg-bar-3;
  }
  .progress-container{
    .explorer-icon, .sage-icon, .adventurer-icon, .learner-icon, .connoisseur-icon{
      path{
        fill:$main-white-color !important;
      }
    }
    .active{
      .explorer-icon, .sage-icon, .adventurer-icon, .learner-icon, .connoisseur-icon{
        path{
          fill:$main-red-color !important;
        }
      }
    }
    .disabled{
      .explorer-icon, .sage-icon, .adventurer-icon, .learner-icon, .connoisseur-icon{
        path{
          fill:$dark-theme-bg-bar-1 !important;
        }
      }
      color: $dark-theme-bg-bar-1 !important;
    }
  }

}

.light{
  .info-icon{
  path{
    fill: $main-black-color;
  }
  }
  .progress-container{
    .explorer-icon, .sage-icon, .adventurer-icon, .learner-icon, .connoisseur-icon{
      path{
        fill:$main-black-color !important;
      }
    }
    .active{
      .explorer-icon, .sage-icon, .adventurer-icon, .learner-icon, .connoisseur-icon{
        path{
          fill:$main-red-color !important;
        }
      }
    }
    .disabled{
      .explorer-icon, .sage-icon, .adventurer-icon, .learner-icon, .connoisseur-icon{
        path{
          fill:$dark-theme-bg-bar-2 !important;
        }
      }
      color: $dark-theme-bg-bar-2 !important;
    }
  }
  .disabled-icon{
    path{
      fill:$dark-theme-bg-bar-2 !important;
    }
  }
}

.progress-container{
  padding: 32px 16px 140px;
  width: 100%;
  align-items: center;
  &__title{
    margin-bottom: 40px;
  }
  .progress-container__block-wrapper__info{
    width: calc(100% - 24px);
  }

  &__chart{
    position: relative;
    &__percentage{
      position: absolute;
      top: 114px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .chart-icon{
    fill:$dark-theme-bg-bar-4;
  }
  &__block-wrapper{
    border-radius: 11px;
    margin-top: 40px;
    width: 100%;
    gap: 16px;
    padding: 12px 16px;
    background-color: $light-gray;
  }
  .progress-container__ranks{
    row-gap: 12px;
    margin-top: 40px;
    width: 100%;
    .progress-container__block-title{
      border-radius: 11px;
      gap: 16px;
      padding: 16px 24px;
      background-color: $light-gray;
      width: 100%;
      &.active{
        .progress-container__block-title__header__left{
          color: $main-red-color !important;
        }

      }
      &.disabled{

      }
      &__header{
        margin-bottom: 4px;
        width: 100%;
        &__left{
          gap:16px;
        }
      }
    }
  }
}
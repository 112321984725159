@import "src/styles/sizes";
@import "src/styles/colors";
@import "src/media";

.dark{
  .start-btn{
    color: $main-black-color;
  }
}
.light{
  .start-btn{
    color: $main-white-color;
  }
}
.section-wrapper{
  padding: 0 16px;
  &__header{
    padding: 0 8px;
    span{
      color: $main-red-color;
    }
  }
  &__content{
    align-items: center;
    gap: 24px;
    .section-wrapper-item__content__border{
      padding: 8px;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      position: relative;
      .start-btn{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 115px;
        left: 50%;
        text-transform: uppercase;
        transform: translateX(-50%);
        border-radius: 59px;
        z-index: 33;
        top: -12px;
        height: 40px;
        background-color: $main-red-color;
      }
      &.active{
        border: 1px solid $main-red-color;
      }
    }
  }
}

.dark{
  .section-wrapper-item__content__wrapper{
    background-color: $main-black-color;
  }
  .section-wrapper-item__content__wrapper__title{
    span{
      color: $main-white-color !important;
    }
    svg{
      path{
        fill: $main-white-color;
      }
    }
  }
}

.section-wrapper-item{
  padding: 0 16px;
  &__header{
    padding: 0 24px;
    gap: 4px;
  }
  img{
    width: 100%;
    max-width: calc(100dvw - 48px);
    object-fit: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
  }
  &__content{
    gap: 24px;
    &__wrapper{
      max-width: calc(535px - 48px - 48px);
      border-radius: 8px;
      position: relative;
      width: 100%;
      background-color: $light-gray;
      @media screen and (max-width: $smartphone-width) {
        max-width: calc(100vw - 24px - 24px);
      }
      &__favorite{
        position: absolute;
        top: 12px;
        right: 12px;
        &.active{
          path{
            fill: $main-red-color !important;
          }
        }
      }
      &__title{
        padding: 16px 16px 20px;
        row-gap: 16px;
        span{
          color: $main-black-color;
        }
        &__lip{
          &.active{
              path{
                fill: $main-red-color !important;
              }
          }
        }
      }
    }
  }
}
@import "/src/styles/colors";
@import '../../media';

.backArrow {
  border: solid $main-red-color;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  cursor: pointer;

  &:hover {
    border-color: rgba(211,34,34,0.7);
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .backArrow {
    padding: 6px;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .backArrow {
    padding: 6px;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height)  {
  .backArrow {
    padding: 7px;
  }
}

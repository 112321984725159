@import "src/styles/colors";

.dark{
  .favorites-container__content__empty{
    svg{
      circle{
        &:nth-of-type(1){
          fill: $dark-theme-bg-bar-1 !important;
        }
      }
      &:nth-child(1){
        fill: $dark-theme-bg-bar !important;
      }
    }
  }
}

.light{
  .favorites-container__content__empty{
    svg{
      &:nth-child(1){
        fill: $dark-theme-bg-bar-1 !important;
      }
    }
  }
}

.favorites-container{
  position: relative;
  &.empty{
    padding: 0 24px;
    height: calc(100dvh - 96px - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__header{
    padding: 16px;
  }
  &__content{
    align-items: center;
    width: 100%;
    row-gap: 24px;
    padding-bottom: 120px;
    &__empty{
      padding: 0 24px;
      text-align: center;
    }
  }
  .btn-change-password{
    position: sticky;
    bottom: 104px
  }
}
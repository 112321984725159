$smartphone-layout-margin: 65px;

$paragraph-spacing: 15px;

$default-border-radius: 30px;
$picture-border-radius: 5px;

$smallDesktopFontSizes: (
        "imageTitle": 41px,
        "largeTitle": 36px,
        "title": 24px,
        "header": 16px,
        "text": 12px,
        "smallText":10px,
        "info": 7px,
);

$desktopFontSizes: (
        "imageTitle": 54px,
        "largeTitle": 48px,
        "title": 35px,
        "header": 24px,
        "text": 16px,
        "smallText":12px,
        "info": 9px,
);
$small-desktop-test-beginning-title-font-size: 26px;
$small-desktop-test-beginning-title-line-height: 30px;

$small-desktop-app-container-side-padding: 30px;
$desktop-app-container-side-padding: 40px;

$small-desktop-carousel-container-side-margin: -30px;
$desktop-carousel-container-side-margin: -40px;

$small-desktop-header-icons-size: 19px;
$desktop-header-icons-size: 24px;

$small-desktop-offer-icons-size: 33px;
$desktop-offer-icons-size: 42px;

$small-desktop-offer-game-icons-size: 22px;
$desktop-offer-game-icons-size: 26px;

$small-desktop-default-gap: 16px;

$desktop-default-gap: 18px;

$sign-up-offers-container-padding: 20px;



